<template>
  <div class="professional-card">
    <div class="professional-card__content">
      <div class="professional-card__header">
        <div class="professional-card__info">
          <div class="professional-card__avatar">
            <Avatar
              class="professional-card__photo"
              :src="currentProfessional.picture"
            />
            <div class="professional-card__status">
              <div
                :class="{
                  'professional-card__status__icon': true,
                  [parseStatusColor(currentProfessional)]: true
                }"
              />
            </div>
          </div>
          <div class="professional-card__personal-info">
            <div class="professional-card__name">
              <Ellipsis>{{ currentProfessional.name }}</Ellipsis>
            </div>
            <div class="professional-card__email">
              <Ellipsis>{{ currentProfessional.email || '-' }}</Ellipsis>
            </div>
          </div>
        </div>
        <div class="professional-card__action">
          <div
            id="mail-icon"
            v-if="currentProfessional.clinic_person.role.includes('_INVITED')"
            :title="countdown && !loading ? 'Aguarde ' + countdown + ' para reenviar o convite' : 'Reenviar convite'"
          >
            <Mail
              v-if="
                (currentProfessional.clinic_person.invited_at &&
                moment().diff(
                  moment(currentProfessional.clinic_person.invited_at),
                  'hours'
                ) < 2) || loading || !userHasPerm('CcConvPro2')
              "
              class="professional-card__icon disabled-mail disabled-button"
              :class="{ loading }"
              id="disabled-mail-icon"
            />
            <!-- <div v-if="countdown" class="countdown">{{ countdown }}</div> -->
            <Mail
              v-if="(!currentProfessional.clinic_person.invited_at ||
                moment().diff(
                  moment(currentProfessional.clinic_person.invited_at),
                  'hours'
              ) >= 2) && !loading"
              class="professional-card__icon"
              id="able-mail-icon"
              @click="reSendInvite(currentProfessional)"
            />
          </div>
          <Pencil

            v-if="!editable"
            class="professional-card__icon"
            @click="handleEditableMode"
          />
          <Close

            v-else
            class="professional-card__icon professional-card__icon--close"
            @click="handleEditableMode"
          />
        </div>
      </div>
      <div
          v-if="expandedBody"
          class="professional-card__body"
          style="z-index: -2;">
        <div class="professional-card__body__buttons">
          <button
              class="form-control"
              @click="$router.push({
                name: 'personalData',
                params: {professionalId: currentProfessional.id}
              })"
          >
            <UserCircle class="button-icons"/>
            <span>Perfil</span>
          </button>
          <button
              v-if="(!loadingPermissionsValue && (userHasPerm('CcPerm1') || userHasPerm('CcPerm2')))"
              class="form-control"
              @click="showPermissions(professional)"
          >
            <Lock class="button-icons"/>
            <span>
              Permissões ativas
              <span v-can="'CcPerm1'" v-if="permissionsUser && permissionsTotal && !loadingPermissionsValue">
                ({{`${permissionsUser.length}/${permissionsTotal.length}`}})
              </span>
            </span>
          </button>
          <button
              v-else
              class="form-control disabled-button"
              :disabled="true"
          >
            <b-spinner
                v-if="loadingPermissionsValue"
                small
                variant="secondary"
                label="Spinning"
                class="mr-1"
            />
            <span v-if="loadingPermissionsValue">Carregando permissões</span>
            <Lock v-if="!loadingPermissionsValue" class="button-icons"/>
            <span v-if="!loadingPermissionsValue">
              Permissões ativas
            </span>
          </button>
        </div>
        <div class="professional-card__body__actions">
          <div>
            <toggle-button
              v-if="userHasPerm('CcConvPro3')"
              :sync="true"
              :height="24"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              v-model="professional.clinic_person.active"
              @input="() => toggleProfessionalStatus(professional)"
            />
            <toggle-button
                v-else-if="!userHasPerm('CcConvPro3')"
                :sync="true"
                :height="24"
                :disabled="true"
                v-model="professional.clinic_person.active"
                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
            />
            <label class="professional-card__body__actions__label" for="active">Ativo</label>
          </div>
          <div v-if="userHasPerm('CcConvPro4')" class="professional-card__edit__remove" @click="removeProfessional(professional)">
            <TrashBin style="fill: #FCBFBA" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPermissions, userHasPermission } from '@/utils/localStorageManager'
import moment from 'moment'

export default {
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    Close: () => import('@/assets/icons/close.svg'),
    Pencil: () => import('@/assets/icons/pencil2.svg'),
    Mail: () => import('@/assets/icons/mail_filled.svg'),
    UserCircle: () => import('@/assets/icons/user_circle.svg'),
    Lock: () => import('@/assets/icons/lock.svg'),
    TrashBin: () => import('@/assets/icons/trash_bin.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis')
  },
  props: {
    professional: { type: Object, require: true }
  },
  data() {
    return {
      currentProfessional: null,
      editable: false,
      loadingPermissionsValue: true,
      permissionsTotal: null,
      permissionsUser: null,
      countdown: null,
      timer: null,
      loading: false,
      expandedBody: false,
      disableSwitch: false
    }
  },
  methods: {
    userHasPerm(perm) {
      return userHasPermission(perm)
    },
    startCountdown() {
      this.timer = setInterval(() => {
        const duration = moment
          .duration(2, 'hours')
          .subtract(
            moment().diff(moment(this.currentProfessional.clinic_person.invited_at))
          )
        let timeString;
        if (duration.hours() === 0) {
          if(duration && duration.minutes < 1){
            timeString = '<1m'
          } else {
            timeString = `${duration.minutes()}m`
          }
        } else {
          const minutes = String(duration.minutes()).padStart(2, '0')
          timeString = `${duration.hours()}h${minutes}m`
        }
        this.countdown = timeString
        if (duration <= 0) {
          clearInterval(this.timer)
          this.countdown = null
        }
      }, 1000)
    },
    handleEditableMode() {
      this.editable = !this.editable
      this.expandedBody = !this.expandedBody
    },
    async removeProfessional(professional) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover <span>${professional.name}</span>? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.api
            .deleteClinicPerson(professional.clinic_person.id)
            .then(() => {
              this.$toast.success('Profissional removido')
              this.$emit('loadProfessionals')
            })
            .catch(err => this.$toast.error(err.message))
        }
      })
    },
    async toggleProfessionalStatus(professional) {
      try {
        const active = professional.clinic_person.active
        this.api.updateClinicPerson(professional.clinic_person.id, { active })
        this.$toast.success('Status atualizado com sucesso')
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    async reSendInvite(professional) {
      try {
        this.loading = true;
        await this.api
          .reSendInvite(professional.clinic_person.id)
          .then(({ data }) => {
            if(data.invited_at) data.invited_at = moment(data.invited_at)
            this.currentProfessional = {
              ...this.currentProfessional,
              clinic_person: data
            }
            this.startCountdown()
          })
        this.$toast.success('Convite reenviado com sucesso')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      }
    },
    parseStatus(professional) {
      if (!professional.clinic_person.active) {
        return 'Inativo'
      } else if (professional.clinic_person.role.includes('_INVITED')) {
        return 'Convite enviado'
      } else if (professional.clinic_person.active) {
        return 'Ativo'
      }
    },
    parseStatusColor(professional) {
      if (!professional.clinic_person.active) {
        return 'inactive'
      } else if (professional.clinic_person.role.includes('_INVITED')) {
        return 'sent'
      } else if (professional.clinic_person.active) {
        return 'active'
      }
    },
    showPermissions(professional) {
      this.$emit('showPermissions', professional)
    },
    async getPermissionsForUser(professional) {
      try {
        const mainFunctionalitiesReq = await this.api.getPermissionsNew(true)
        const permissionsUserReq = await this.api.getPermissionKeys(
          professional.id
        )

        const mainFuncionalitiesList = mainFunctionalitiesReq.data
        let permissionsUser = permissionsUserReq.data

        permissionsUser = permissionsUser.filter(
          permission =>
            !Object.keys(mainFuncionalitiesList).includes(permission)
        )

        this.permissionsTotal = getPermissions().filter(
          permission =>
            !Object.keys(mainFuncionalitiesList).includes(permission)
        )
        this.permissionsUser = permissionsUser
        this.loadingPermissionsValue = false

        return this.permissionsUser
      } catch (error) {
        console.error('Erro ao obter permissões:', error)
        throw error
      }
    }
  },
  async mounted() {
    await this.getPermissionsForUser(this.currentProfessional)
  },
  created() {
    this.currentProfessional = this.professional
    if (
      this.currentProfessional.clinic_person.invited_at &&
      moment().diff(
        moment(this.currentProfessional.clinic_person.invited_at),
        'hours'
      ) < 2
    ) {
      this.startCountdown()
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    professional(value) {
      this.currentProfessional = value
      this.getPermissionsForUser(this.currentProfessional)
    }
  }
}
</script>
<style lang="scss" scoped>
.professional-card {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  margin-bottom: 20px;

  &__content {
    padding: 8px 16px 8px 16px;
  }

  &__header {
    display: flex;
  }

  &__info {
    width: 75%;
    display: flex;
    flex: 3;
    gap: 16px;
    align-items: center;
  }

  &__action {
    display: flex;
    flex: 1;
    align-items: baseline;
    max-width: fit-content;
    gap: 8px;
    margin-top: 0.3em;
  }

  &__photo {
    width: 25%;
    padding: 0.1rem;
  }

  &__personal-info {
    max-width: 70%;
  }

  &__name {
    font-family: 'Nunito Sans',serif;
    font-weight: 700;
    font-size: 14px;
    color: var(--type-active);
    line-height: 1.3rem;
  }

  &__status {
    position: relative;
    top: -7px;
    right: -39px;
    transform: scale(1.5);
    &__icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: grey;
      &.active {
        background-color: var(--states-success);
      }
      &.sent {
        background-color: var(--light-orange-500);
      }
    }

    &__label,
    &__re-sent {
      display: flex;
      flex-direction: row;
      gap: 3px;
      font-weight: 600;
      font-size: 12px;
      color: var(--type-placeholder);
    }
    &__re-sent {
      &.sent {
        color: var(--blue-500);
        cursor: pointer;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;

    cursor: pointer;
    &--close {
      width: 24px;
      height: 24px;
      fill: var(--neutral-400);
      cursor: pointer;
    }
  }

  &__body {
    padding: 16px;
    border-top: 1px solid var(--neutral-200);
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;

      button {
        font-family: 'Nunito Sans', 'serif';
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 30px;
        color: var(--blue-500);
        font-size: 13px;
        font-weight: 600;
        border: 2px solid var(--blue-500) !important;
        border-radius: 4px !important;
        padding: 4px 8px 4px 8px;
        cursor: pointer;
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;

      &__label{
        font-family: 'Nunito Sans', 'serif';
        font-weight: 400;
        font-size: 13px;
        color: var(--type-active);
        margin-bottom: 0;
        margin-left: 8px
      }
    }
  }

  &__roleNemail {
    padding-top: 0.3rem;
  }

  &__email {
    font-family: 'Nunito Sans', 'serif';
    color: var(--blue-500);
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
  }

  &__permissions {
    font-weight: 600;
    font-size: 14px;
  }

  &__seeall-permissions {
    padding: 10px 20px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border-top: 1px solid var(--neutral-200);
    color: var(--light-orange-500);
    cursor: pointer;
  }

  &__edit {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-top: 1px solid var(--neutral-200);
    &__remove {
      font-weight: 600;
      font-size: 16px;
      color: var(--states-error);
      cursor: pointer;
    }

    &__status {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-placeholder);
    }
  }

  #mail-icon {
    position: relative;
    top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .disabled-mail {
    opacity: 0.3;
  }

  .loading {
    cursor: wait !important;
  }
}

.button-icons {
  width: 20px;
  height: 20px;
  fill: var(--blue-500);
  margin-right: 8px;
}

.disabled-button {
  cursor: not-allowed !important;
}
</style>
