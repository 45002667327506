<template>
  <b-modal
    id="invite-professional-modal"
    hide-header
    :hide-footer="form.submitted"
    @hidden="resetModal"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Adicionar novo(a) profissional</p>
      <v-close
        class="close"
        @click="$bvModal.hide('invite-professional-modal')"
      />
    </div>

    <div class="body d-flex flex-column" v-if="!form.submitted">
      <div class="form-group">
        <label for="name">Nome</label>
        <validation-provider
          mode="lazy"
          name="nome"
          rules="required"
          v-slot="{ touched, errors }"
        >
          <input
            autocomplete="off"
            id="name"
            type="text"
            v-model="form.name"
            class="form-control"
            :class="{ error: touched && errors.length > 0 }"
          />
          <span class="error-message" v-if="touched && errors.length > 0">{{
            errors[0]
          }}</span>
        </validation-provider>
      </div>

      <div class="form-group">
        <label for="email">E-mail</label>
        <validation-provider
          mode="lazy"
          name="e-mail"
          rules="required|email"
          v-slot="{ touched, errors }"
        >
          <input
            autocomplete="off"
            id="email"
            type="text"
            v-model="form.email"
            class="form-control"
            :class="{ error: touched && errors.length > 0 }"
          />
          <span class="error-message" v-if="touched && errors.length > 0">{{
            errors[0]
          }}</span>
        </validation-provider>
      </div>

      <div class="form-group mb-0">
        <label for="role">Atuação</label>
        <validation-provider
          name="atuação"
          rules="required"
          v-slot="{ touched, errors }"
        >
          <multiselect
            id="role"
            v-model="form.role"
            :options="roles"
            track-by="value"
            label="name"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            placeholder=""
            class="with-border"
            :class="{ error: touched && errors.length > 0 }"
          >
            <template slot="caret">
              <div class="chevron">
                <v-chevron-down />
              </div>
            </template>

            <template slot="noOptions">
              Nenhuma opção
            </template>

            <template slot="noResult">
              Nenhum resultado
            </template>
          </multiselect>
          <span class="error-message" v-if="touched && errors.length > 0">{{
            errors[0]
          }}</span>
        </validation-provider>
      </div>
    </div>

    <div class="body" v-else>
      <p class="check-container">
        <span class="check">
          <span class="container">
            <v-check class="icon" />
          </span>
        </span>
      </p>

      <p class="check-message">
        Convite enviado com sucesso para o email
        <span>{{ form.email }}</span>
      </p>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="invalid"
          @click="addProfessional"
          >Enviar convite</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Check from '@/assets/icons/check.svg'

export default {
  components: {
    'v-close': Close,
    'v-chevron-down': ChevronDown,
    'v-check': Check
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      form: this.getDefaultForm(),
      roles: [
        { value: 'SECRETARY', name: 'Secretário(a)' },
        { value: 'NURSE', name: 'Enfermeiro(a)' },
        { value: 'TECHNICIAN', name: 'Técnico(a)' },
        { value: 'TECHNOLOGIST', name: 'Tecnólogo(a)' },
        { value: 'MANAGER', name: 'Gestor(a)' },
        { value: 'DOCTOR', name: 'Médico(a)' },
        { value: 'DOCTOR_MANAGER', name: 'Médico(a) Gestor(a)' },
        { value: 'DOCTOR_ANESTHETIST', name: 'Anestesista' }
      ],
    }
  },
  methods: {
    getDefaultForm() {
      return {
        name: '',
        email: '',
        role: null,
        submitted: false
      }
    },
    resetModal() {
      this.form = this.getDefaultForm()
    },
    addProfessional() {
      const isLoading = this.$loading.show()
      const data = {
        ...this.form,
        clinic_id: this.clinic.id,
        role: this.form.role.value
      }

      this.api.createClinicPerson(data)
        .then(() => {
            this.form.submitted = true
            this.$toast.success('Profissional cadastrado com sucesso!')
            this.$emit('loadProfessionals')
            this.$bvModal.hide('invite-professional-modal')
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
  }
}
</script>
<style lang="scss">
  #invite-professional-modal {
    .modal-footer {
      border-top: 0;
    }

    .modal-body {
      padding: 0 !important;
      display: flex;
      flex-direction: column;

      .header {
        padding: 24px;
        border-bottom: 1px solid var(--neutral-200);

        .title {
          font-weight: 600;
          font-size: 18px;
          color: var(--type-active);
          margin: 0;
        }

        .close {
          width: 24px;
          height: 24px;
          fill: black;
          cursor: pointer;
        }
      }

      .body {
        flex: 1;
        align-items: stretch;
        padding: 24px;

        .check-container {
          margin-bottom: 32px;
          display: flex;
          justify-content: center;

          .check {
            width: 96px;
            height: 96px;
            background-color: #dbfff0;
            border: 16px solid #dbfff0;
            border-radius: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;

            .container {
              width: 64px;
              height: 64px;
              border-radius: 16px;
              background-color: var(--states-success);
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .icon {
              width: 24px;
              height: 24px;
              stroke: var(--neutral-000);
            }
          }
        }

        .check-message {
          text-align: center;
          font-family: 'Nunito Sans';
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--type-active);

          span {
            color: var(--blue-500);
          }
        }
      }
    }
  }
</style>
