<template>
    <b-modal
      id="permissions-modal"
      centered
      hide-header
      hide-footer
      size="lg"
      @show="onShow"
    >
      <div class="header">
        <div class="header-content">
            <p>Permissionamentos - {{ person ? person.name : '' }}</p>
            <v-close class="close-svg" @click="$bvModal.hide('permissions-modal')" />
        </div>
      </div>
      <div class="body" v-if="Object.keys(permissions).length">
        <div class="subtitle">Selecione abaixo quais funcionalidades o profissional poderá ter acesso</div>
        <div class="card-group" v-for="(group, i) in Object.keys(permissions)" :key="group">
          <div class="custom-card-header">
            <div class="group-title">{{ `${group} (${countGroup(group)})` }}</div>
            <v-chevron-down class="chevron" v-b-toggle="`collapse-${i}`" />
          </div>
          <b-collapse :id="`collapse-${i}`" class="collapse-wrapper">
            <div class="all-option">
              <div class="checkbox">
                <v-check
                  :disabled="!canEdit"
                  :ref="`${group}_all`"
                  @input="(checked) => handleAllPermissionsGroup(group, checked)"
                />
                <div>Todas as permissões</div>
              </div>
              <div class="checkbox">
                <v-check
                  :disabled="!canEdit"
                  :ref="`${group}_view`"
                  @input="(checked) => handleAllPermissionsGroup(group, checked, true)"
                />
                <div>Apenas visualizar</div>
              </div>
            </div>

            <div
              v-for="subgroup in Object.keys(permissions[group])"
              :key="subgroup"
              class="card-subgroup"
            >
              <div class="card-subgroup">
                <div class="subgroup-name">{{ subgroup }}</div>
                <div class="checkbox">
                  <v-check
                    :disabled="!canEdit"
                    :ref="`${group}_${subgroup}_all`"
                    @input="(checked) => handleAllPermissionsSubgroup(group, subgroup, checked)"
                  />
                  <div>Todas as permissões</div>
                </div>
                <div
                  v-for="name in Object.keys(permissions[group][subgroup])"
                  :key="`${subgroup}_${name}`"
                  class="checkbox"
                >
                  <v-check
                    :disabled="!canEdit"
                    v-model="permissions[group][subgroup][name].selected"
                    @input="(checked) => handlePermission(group, subgroup, name, checked)"
                  />
                  <div>{{ name }}</div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="loading-container" v-else>
        <b-spinner small variant="primary" class="m-2"></b-spinner> Carregando permissões...
      </div>
  </b-modal>
</template>
<script>
import { debounce } from 'lodash';
import { userHasPermission, getCurrentUser } from '@/utils/localStorageManager'
import Close from '@/assets/icons/close.svg'
import Check from '@/components/General/Check'
import ChevronDown from '@/assets/icons/chevron-down.svg'
export default {
  components: {
    'v-close': Close,
    'v-check': Check,
    'v-chevron-down': ChevronDown,
  },
  props: {
    person: Object,
  },
  created() {
    this.createOrDeletePermissions = debounce(this.handlePermissionsOnServer, 500);
  },
  data() {
    return {
      user: getCurrentUser(),
      canEdit: false,
      permissionKeys: [],
      permissions: {},
      selected: [],
      keys: [],
    }
  },
  methods: {
    async onShow() {
      await this.getPermissions();
      await this.loadPermissions();
    },
    async getPermissions() {
      try {
        const res = await this.api.getPermissionsNew(true)
        this.permissions = res.data;
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    async loadPermissions() {
      const loading = this.$loading.show();
      const res = await this.api.getPermissionKeys(this.person.id);
      this.permissionKeys = res.data;
      Object.keys(this.permissions)
        .map(group =>
          Object.keys(this.permissions[group])
          .map(subgroup =>
            Object.keys(this.permissions[group][subgroup])
              .map(name => {
                this.permissions = {
                  ...this.permissions,
                  [group]: {
                    ...this.permissions[group],
                    [subgroup]: {
                      ...this.permissions[group][subgroup],
                      [name]: {
                        ...this.permissions[group][subgroup][name],
                        selected: this.permissionKeys.includes(this.permissions[group][subgroup][name].key),
                      }
                    }
                  }
                }
              }
            )
          )
        )
      loading.hide();
    },
    countGroup(group) {
      let total = 0;
      let selected = 0;
      Object
        .keys(this.permissions[group])
        .map(subgroup => {
          Object.keys(this.permissions[group][subgroup])
            .map(name => {
              total++;
              if (this.permissions[group][subgroup][name].selected) {
                selected++;
              }
            })
        });
      return `${selected}/${total}`;
    },
    async handleAllPermissionsGroup(group, checked, justView) {
      this.keys = []
      this.$refs[`${group}_all`][0].checked = justView ? false : checked;
      this.$refs[`${group}_view`][0].checked = !justView ? false : checked;
      const loading = this.$loading.show();
      Object.keys(this.permissions[group])
        .map(async subgroup => {
          this.$refs[`${group}_${subgroup}_all`][0].checked = !justView ? checked : false
          await this.handleAllPermissionsSubgroup(group, subgroup, checked, justView)
        })
      loading.hide();
    },  
    async handleAllPermissionsSubgroup(group, subgroup, checked, justView) {
      Object.keys(this.permissions[group][subgroup])
        .map(async name => await this.handlePermission(group, subgroup, name, this.justViewValidation(justView, name, checked)))
    },
    async handlePermission(group, subgroup, name, checked) {

      this.keys.push({ key: this.permissions[group][subgroup][name].key, checked })
      this.createOrDeletePermissions(this.keys)

      this.permissions = {
        ...this.permissions,
        [group]: {
          ...this.permissions[group],
          [subgroup]: {
            ...this.permissions[group][subgroup],
            [name]: {
              ...this.permissions[group][subgroup][name],
               selected: checked,
            }
          }
        }
      }
    },
    async handlePermissionsOnServer(keys) {
      const personId = this.person.id
      try {
        await this.api.handlePermissions({ personId, keys })
        this.keys = [];
      } catch (err) {
        this.$toast.error(`Não foi possível adicionar ou remover as permissões`)
      }
    },
    justViewValidation(justView, name, checked) {
      if (!justView || name === 'Visualizar') return checked
      return false
    },
  },
  watch: {
    person() {
      this.canEdit = (this.user.id !== this.person.id) && userHasPermission('CcPerm2');
    }
  }
}
</script>
<style lang="scss">
  #permissions-modal {
    .modal-content {
      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        margin: 16px;
      }
      .modal-body {
        padding: 0px;
        .header {
          font-size: 18px;
          font-weight: 600;
          color: var(--type-active);
          border-bottom: 1px solid var(--neutral-200);

          .header-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin: 24px;

              .close-svg {
                  height: 24px;
                  width: 24px;
                  fill: var(--neutral-500);
              }
          }
        }
        .body {
          .subtitle {
            font-weight: 500;
            font-size: 18px;
            color: var(--type-active);
          }
          padding: 24px;
          .card-group {
            width: 100%;
            background: #F4F5FB;
            border-radius: 8px;
            padding: 24px;
            margin-top: 16px;
            .chevron {
              height: 25px;
              width: 25px;
              svg {
                stroke: var(--dark-blue);
              }
              &.not-collapsed {
                animation: rotateUp .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
              }
              &.collapsed {
                 animation: rotateDown .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
              }

               @keyframes rotateUp {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(180deg);
                }
              }

              @keyframes rotateDown {
                0% {
                    transform: rotate(180deg);
                }
                100% {
                    transform: rotate(0deg);
                }
              }
            }
            .chevron:focus {
              outline: none !important;
            }
            .subgroup-name {
              font-weight: 600;
              font-size: 18px;
              color: var(--neutral-700);
              margin-bottom: 10px;
            }
            .custom-card-header {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }
            .group-title {
              font-size: 18px;
              font-weight: 700;
              color: var(--blue-500);
            }
            .collapse-wrapper {
              width: 100%;
            }
            .all-option {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 30px;
              margin: 20px 0;
            }
            .card-subgroup {
              width: 100%;
              padding: 10px;
              border-radius: 8px;
              background-color: white;
              margin-top: 16px;
            }
            .checkbox {
              display: flex;
              align-items: center;
              margin-top: 3px;
              font-weight: 400;
              color: var(--type-active);
            }
          }
        }
      }
    }
  }
</style>
