<template>
  <div class="professional-view">
    <b-row>
      <b-col col xl="8" lg="6" md="4">
          <v-input-search
            placeholder="Nome do profissional ou email"
            v-model="search"
            debounce="300"
          />
      </b-col>
      <b-col>
        <v-select
          placeholder="Selecionar atuação"
          v-model="role"
          :options="roles"
        />
      </b-col>
      <b-col v-can="'CcConvPro2'">
        <b-button
          variant="primary"
          class="create-button"
          v-b-modal.invite-professional-modal
          >
            Novo profissional
          </b-button>
      </b-col>
    </b-row>

    <div v-for="role in categories" :key="role.name">
      <div
        v-if="hasSome(role)"
        class="category"
      >
        <div class="category-title">{{role.name}}</div>
        <b-row>
          <b-col
            col xl="3" lg="4" md="6" class="col-xxl-2"
            v-for="(professional, index) of professionals.filter(el => role.values.includes(el.clinic_person.role.replace('_INVITED', '')))"
            :key="index"
          >
            <v-professional-card
              :professional="professional"
              @showPermissions="showPermissions"
              @loadProfessionals="loadProfessionals"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <v-invite-professional-modal @loadProfessionals="loadProfessionals" />

    <v-permissions-modal
      :person="person"
      @loadProfessionals="loadProfessionals"
    />

  </div>
</template>

<script>
import ProfessionalCard from '@/components/Config/Person/ProfessionalCard'
import PermissionsModal from '@/components/Config/Person/PermissionsModal'
import InviteProfessionalModal from '@/components/Config/Person/InviteProfessionalModal'
import Select from '@/components/Select'
import InputSearch from '@/components/General/InputSearch'

export default {
  name: 'ProfessionalView',
  components: {
    'v-permissions-modal': PermissionsModal,
    'v-professional-card': ProfessionalCard,
    'v-invite-professional-modal': InviteProfessionalModal,
    'v-select': Select,
    'v-input-search': InputSearch,
  },
  created() {
    this.loadProfessionals()
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      person: null,
      search: '',
      role: null,
      roles: [
        { label: 'Secretário(a)', value: 'SECRETARY' },
        { label: 'Enfermeiro(a)', value: 'NURSE'  },
        { label: 'Técnico(a)', value: 'TECHNICIAN'  },
        { label: 'Tecnólogo(a)', value: 'TECHNOLOGIST'  },
        { label: 'Médico(a)', value: 'DOCTOR'  },
        { label: 'Médico(a) Gestor(a)', value: 'DOCTOR_MANAGER' },
        { label: 'Gestor', value: 'MANAGER' },
        { label: 'Anestesista', value: 'DOCTOR_ANESTHETIST'}
      ],
      categories: [
        { name: 'Anestesista', values: ['DOCTOR_ANESTHETIST'] },
        { name: 'Secretário(a)', values: ['SECRETARY'] },
        { name: 'Enfermeiro(a)', values: ['NURSE']  },
        { name: 'Técnico(a)', values: ['TECHNICIAN']  },
        { name: 'Tecnólogo(a)', values: ['TECHNOLOGIST']  },
        { name: 'Médico(a)', values: ['DOCTOR']  },
        { name: 'Gestor', values: ['MANAGER', 'DOCTOR_MANAGER'] },
      ],
      professionals: []
    }
  },
  methods: {
    hasSome(role) {
      return this.professionals.some(el => role.values.includes(el.clinic_person.role.replace('_INVITED', '')))
    },
    loadProfessionals() {
      const isLoading = this.$loading.show()
      this.api
        .showClinicProfessionals(this.clinic.id, this.search, this.role)
        .then(res => {
          this.professionals = res.data;
        })
        .catch(err => console.log('Err', err))
        .finally(() => {
          isLoading.hide()
        })
    },
    showPermissions(professional) {
      this.person = professional;
      this.$bvModal.show('permissions-modal')
    }
  },
  watch: {
    search() { this.loadProfessionals() },
    role() { this.loadProfessionals() },
  }
}
</script>

<style lang="scss" scoped>
  @media (min-width: 1600px) {
    .col-xxl-2 {
      flex: 0 0 25%;
      max-width: 20%;
    }
  }
  .category {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    border-bottom: 1px solid var(--neutral-200);
    gap: 8px;

    &-title {
      font-family: "Red Hat Display";
      font-weight: 700;
      font-size: 18px;
      color: var(--neutral-700);
    }
  }
  .create-button {
    width: 100%;
    height: 38px;
    white-space: nowrap;
  }
</style>
